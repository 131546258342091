import MuiButton from "./MuiButton";
import MuiPaper from "./MuiPaper";
import MuiTableHead from "./MuiTableHead";
import MuiTypography from "./MuiTypography";

export default {
  MuiButton,
  MuiPaper,
  MuiTableHead,
  MuiTypography,
};
