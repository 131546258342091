import * as amplitude from "./amplitude";
import * as googleAnalytics from "./googleAnalytics";
import UrlPattern from "url-pattern";

export const sendEvent = (eventType, eventProperties) => {
  amplitude.sendEvent(eventType, eventProperties);
  if (process.env.NODE_ENV === "production") {
    googleAnalytics.sendEvent(eventType, eventProperties);
  }
};

export const sendPageViews = (location) => {
  const trackedRoute = PAGE_HIT_TRACKING.filter((page) => {
    if (page.url.match(location)) {
      return true;
    }
    return false;
  });
  if (trackedRoute.length > 0) {
    amplitude.sendEvent(trackedRoute[0].eventName, {
      url: trackedRoute[0].sendUrl ? location : null,
    });
    if (process.env.NODE_ENV === "production") {
      googleAnalytics.sendEvent(trackedRoute[0].eventName, {
        url: trackedRoute[0].sendUrl ? location : null,
      });
    }
  }
};

export const PAGE_HIT_TRACKING = [
  {
    eventName: "viewed_home_page",
    sendUrl: false,
    url: new UrlPattern("/"),
  },
  {
    eventName: "viewed_blog_page",
    sendUrl: true,
    url: new UrlPattern("/blog*"),
  },
  {
    eventName: "viewed_career_page",
    sendUrl: true,
    url: new UrlPattern("/career*"),
  },
  {
    eventName: "viewed_privacy_policy_page",
    sendUrl: true,
    url: new UrlPattern("/privacy_policy"),
  },
];
