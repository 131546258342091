import { sendPageViews } from "./src/lib/analytics/events";
import SiteWrapper from "./src/components/Wrappers/SiteWrapper";
import React from "react";

export const wrapPageElement = ({ element, props }) => {
  sendPageViews(window.location.pathname);
  return (
    <SiteWrapper {...props} isSSR={false}>
      {element}
    </SiteWrapper>
  );
};
