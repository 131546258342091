import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import NavbarWrapper from "../../../../common/src/components/Navbar";
import Drawer from "../../../../common/src/components/Drawer";
import Button from "../../../../common/src/components/Button";
import Logo from "../../../../common/src/components/UIElements/Logo";
import Box from "../../../../common/src/components/Box";
import HamburgMenu from "../../../../common/src/components/HamburgMenu";
import Container from "../../../../common/src/components/UI/Container";
import { DrawerContext } from "../../../../common/src/contexts/DrawerContext";
import ScrollSpyMenu from "../../../../common/src/components/ScrollSpyMenu";
import LogoImage from "../../../../common/src/assets/image/saasClassic/logo-white.png";
import LogoImageAlt from "../../../../common/src/assets/image/saasClassic/logo.png";
import { sendEvent } from "../../../lib/analytics/events";

const Navbar = ({
  navbarStyle,
  logoStyle,
  button,
  row,
  menuWrapper,
  source = null,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      markJson {
        MENU_ITEMS {
          label
          path
          offset
          siteLink
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE",
    });
  };

  /*
  const calendars = [
    "https://calendly.com/markbeta/demo",
    "https://calendly.com/betamark/30min",
  ];

  const prefill = {
    email: "",
    firstName: "Jon",
    lastName: "Snow",
  };
  const utm = {};

  const pageSettings = {
    backgroundColor: "ffffff",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "00a2ff",
    textColor: "4d5055",
  };

  const getRandomCalendar = () => {
    const shuffledCalendars = calendars.sort(() => 0.5 - Math.random());

    sendEvent("click_free_trial", { source: "navbar" });
    const url = shuffledCalendars[0];
    openPopupWidget({ url, prefill, pageSettings, utm });
  };*/

  const redirectToFreeTrial = () => {
    sendEvent("click_free_trial", { source: "navbar" });
    window.location = `https://apps.shopify.com/mark`;
  };

  return (
    <NavbarWrapper {...navbarStyle} className="saas_navbar">
      <Container>
        <Box {...row}>
          <Logo
            to="/"
            style={{ cursor: "pointer" }}
            logoSrc={source === "blog" ? LogoImageAlt : LogoImage}
            title="MARK AI"
            logoStyle={logoStyle}
            className="main-logo"
          />
          <Logo
            to="/"
            style={{ cursor: "pointer" }}
            logoSrc={LogoImageAlt}
            title="MARK AI"
            logoStyle={logoStyle}
            className="logo-alt"
          />
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={Data.markJson.MENU_ITEMS}
              offset={-70}
              source={source}
            />
            <div className="main_menu scrollspy__menu">
              <li>
                <a
                  href={
                    typeof window !== "undefined"
                      ? `https://app.${window.location.host}`
                      : `https://app.markadz.com`
                  }
                >
                  Sign in
                </a>
              </li>
            </div>
            <a href="/#" className="navbar_button">
              <Button
                {...button}
                title={"GET STARTED"}
                onClick={() => redirectToFreeTrial()}
              />
            </a>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#fff" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={Data.markJson.MENU_ITEMS}
                drawerClose={true}
                offset={-100}
              />
              <a
                href="https://apps.shopify.com/mark"
                className="navbar_drawer_button"
              >
                <Button {...button} title="GET STARTED" />
              </a>
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: "70px",
    display: "block",
  },
  row: {
    flexBox: true,
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  logoStyle: {
    maxWidth: ["200px", "250px"],
  },
  button: {
    type: "button",
    fontSize: "13px",
    fontWeight: "700",
    borderRadius: "4px",
    pl: "15px",
    pr: "15px",
    colors: "secondaryWithBg",
    minHeight: "auto",
    height: "40px",
  },
  menuWrapper: {
    flexBox: true,
    alignItems: "center",
  },
};

export default Navbar;
