import React, { useEffect, useState, Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import Sticky from "react-stickynode";
import { DrawerProvider } from "../../../common/src/contexts/DrawerContext";
import { saasClassicTheme } from "../../../common/src/theme/saasClassic";
import { ResetCSS } from "../../../common/src/assets/css/style";
import "@redq/reuse-modal/es/index.css";
import Navbar from "../../containers/Mark/Navbar";
import Footer from "../../containers/Mark/Footer";
import {
  GlobalStyle,
  ContentWrapper,
} from "../../containers/Mark/saasClassic.style";

const SiteWrapper = ({ children, isSSR }) => {
  const getSource = () => {
    return isSSR === false && window && window.location
      ? window.location.pathname.includes("/blog") ||
        window.location.pathname.includes("/career")
        ? "blog"
        : ""
      : null;
  };
  const [source, setSource] = useState(getSource());
  useEffect(() => {
    setSource(getSource());
  }, [isSSR]);

  return (
    <ThemeProvider theme={saasClassicTheme}>
      <Fragment>
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar source={source} />
            </DrawerProvider>
          </Sticky>
          {children}
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default SiteWrapper;
